import { CancelToken } from 'axios';
import moment from 'moment';
import { useState } from 'react';
import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

import { FilterState } from '../enum/FilterState';
import {
  AverageTimeByPeople,
  PeopleInAreaDate,
  PresenceByArea,
  RankByProfessionData,
  RankByProfession,
  Burndown,
  Planning,
  Timeline,
  CalendarEvent,
  CollaboratorTimelineEvent,
  CollaboratorTimelineLabel,
  AreaTimeline,
} from '../containers/indicators/types/Indicators';
import { DailySummaryMonitor, MediaData } from '../containers/dailySummary/types/DailySummaryTypes';
import { downloadPdf } from '../services/fileManagmentService';
import {
  getCollaborators,
  getCompanies,
  getProfessions,
  getAreas,
  getRankChartData,
  getAverageTimeChartData,
  getCalendarData,
  getTCollaboratorimelineData,
  getBurndownChartData,
  getPeopleInAreasChartData,
  getProductivityByArea,
  generateAccessManagement,
  generateAreaMonitoringHistory,
  generateDailyReport,
  getReport,
  getSummary,
  getSummaryMedia,
  getMonitoredAreasData,
  getMonitoredCollaborators,
} from '../services/TrackfyService';
import { Area, Collaborator, Company, Profession } from '../types/Filters';
import { useIsMounted } from '.';
import {
  getAreaTimelineData,
  getHeatMapData,
  getMachinesSummary,
  getTimelineData,
  putMachineStatus,
} from '../services/api/TrackfyService';
import {
  MonitoredAreasData,
  MonitoredData,
  MonitoringCollaboratorData,
} from '../types/MonitoredData';
import { MachinesSummaryData } from '../containers/machinesSummary/types/MachinesSummaryTypes';
import { IHeatmapArea } from '../containers/moviment/HeatMap/types/HeatMapData';

export const useTrackfyApi = () => {
  const [companies, setCompanies] = useState<Company[]>([]);
  const [professions, setProfessions] = useState<Profession[]>([]);
  const [areas, setAreas] = useState<Area[]>([]);
  const [companiesState, setCompaniesState] = useState<string>('');
  const [professionsState, setProfessionsState] = useState<string>('');
  const [areasState, setAreasState] = useState<string>('');

  const isMounted = useIsMounted();

  // Consumes entities endpoints=======================================================================
  const getAreasApi = async (cancelToken: CancelToken, excludeImproductZone?: boolean): Promise<void> => {
    setAreasState(FilterState.PEDDING);

    const areasList: Area[] = await getAreas(cancelToken, excludeImproductZone);

    if (!isMounted.current) return;

    setAreas(areasList);
    setAreasState(FilterState.DONE);
  };

  const getCollaboratorsApi = async (
    clientId: number,
    companyId: number,
    professionId: number,
    cancelToken: CancelToken,
    setCollaborators: (value: Collaborator[]) => void,
    setCollaboratorsState: (value: string) => void
  ): Promise<void> => {
    setCollaboratorsState(FilterState.PEDDING);
    const collaborators: Collaborator[] = await getCollaborators(clientId, companyId, professionId, cancelToken);

    if (!isMounted.current) return;

    setCollaborators(collaborators);
    setCollaboratorsState(FilterState.DONE);
  };

  const getCompaniesApi = async (cancelToken: CancelToken, operational?: boolean): Promise<void> => {
    setCompaniesState(FilterState.PEDDING);

    const companiesList: Company[] = await getCompanies(operational ?? false, cancelToken);

    if (!isMounted.current) return;

    setCompanies(companiesList);
    setCompaniesState(FilterState.DONE);
  };

  const getProfessionsApi = async (cancelToken: CancelToken): Promise<void> => {
    setProfessionsState(FilterState.PEDDING);

    const professionsList: Profession[] = await getProfessions(cancelToken);

    if (!isMounted.current) return;

    setProfessions(professionsList);
    setProfessionsState(FilterState.DONE);
  };

  // Consumes charts endpoints=========================================================================
  const getAverageTimeChartDataApi = async (
    clientId: number,
    areaId: number,
    collaboratorId: number,
    companyId: number,
    professionId: number,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setIsLoading: (value: boolean) => void,
    setAverageTimeChartData: (value: AverageTimeByPeople[]) => void,
    dividerPerFive: (base: AverageTimeByPeople[]) => Array<AverageTimeByPeople[]>,
    setAverageValues: (arrayAvarageValues: Array<AverageTimeByPeople[]>) => void
  ): Promise<void> => {
    setIsLoading(true);
    batchedUpdates(() => {
      setAverageValues([]);
    });

    const data: AverageTimeByPeople[] = await getAverageTimeChartData(
      clientId,
      companyId,
      professionId,
      areaId,
      collaboratorId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      batchedUpdates(() => {
        setAverageValues(dividerPerFive(data));
        setAverageTimeChartData(dividerPerFive(data)[0]);
      });
    } else {
      setAverageTimeChartData([{ value: 0, label: '0hrs', name: 'Sem Registros' } as AverageTimeByPeople]);
    }

    setIsLoading(false);
  };

  const getBurndownChartDataApi = async (
    clientId: number,
    companies: string,
    professions: string,
    areas: string,
    initialDate: number,
    finalDate: number,
    cancelToken: CancelToken,
    setIsLoading: (value: boolean) => void,
    setPlanningData: (value: any[]) => void
  ): Promise<void> => {
    setIsLoading(true);

    const data: Burndown = await getBurndownChartData(
      clientId,
      companies,
      professions,
      areas,
      initialDate,
      finalDate,
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.series && data.series.length > 0) {
      setPlanningData(
        data.series.map((element: Planning) => ({
          time: element.time,
          value: parseFloat(element.value.toString()),
          type: element.type,
        }))
      );
    } else {
      setPlanningData([]);
    }
    setIsLoading(false);
  };

  const getCalendarDataApi = async (
    clientId: number,
    collaboratorId: number,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setEvents: (value: CalendarEvent[]) => void,
    setInitDate: (value: Date) => void,
    setIsLoading: (value: boolean) => void
  ): Promise<void> => {
    setIsLoading(true);
    const data: CalendarEvent[] = await getCalendarData(
      clientId,
      collaboratorId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      moment().valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      setEvents(data);
      setInitDate(new Date(data[0].start));
    } else {
      setEvents([]);
    }

    setIsLoading(false);
  };

  const getCollaboratorTimelineDataApi = async (
    clientId: number,
    collaboratorId: number,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setLabels: (value: CollaboratorTimelineLabel[]) => void,
    setTimelineData: (value: CollaboratorTimelineEvent[]) => void,
    setTimelineValues: (values: Array<CollaboratorTimelineEvent[]>) => void,
    dividerPerEighteen: (base: any[]) => Array<any[]>,
    setIsLoading: (value: boolean) => void,
    setIsEmpty: (value: boolean) => void
  ): Promise<void> => {
    setIsLoading(true);

    const data: any = await getTCollaboratorimelineData(
      clientId,
      collaboratorId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.timeline && data.timeline.length > 0) {
      batchedUpdates(() => {
        setTimelineValues(dividerPerEighteen(data.timeline));
        setTimelineData(dividerPerEighteen(data.timeline)[0]);
      });

      setLabels(data.labels);
      setIsEmpty(false);
    } else {
      setLabels([]);
      setIsEmpty(true);
    }

    setIsLoading(false);
  };

  const getPeopleInAreasChartDataApi = async (
    clientId: number,
    companyId: string,
    professionId: string,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setIsLoading: (value: boolean) => void,
    setPeopleInAreasChartData: (value: PeopleInAreaDate[]) => void,
    dividerPerFifteen: (base: PeopleInAreaDate[]) => Array<PeopleInAreaDate[]>,
    setPeopleValues: (arrayAvarageValues: Array<PeopleInAreaDate[]>) => void
  ): Promise<void> => {
    setIsLoading(true);
    batchedUpdates(() => {
      setPeopleValues([]);
    });

    const data: PeopleInAreaDate[] = await getPeopleInAreasChartData(
      clientId,
      companyId,
      professionId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      batchedUpdates(() => {
        setPeopleValues(dividerPerFifteen(data));
        setPeopleInAreasChartData(dividerPerFifteen(data)[0]);
      });
    } else {
      setPeopleInAreasChartData([]);
    }

    setIsLoading(false);
  };

  const getProductivityByAreaApi = async (
    clientId: number,
    companyId: string,
    professionId: string,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setIsLoading: (value: boolean) => void,
    setPresenceAreaChartData: (value: PresenceByArea[]) => void
  ): Promise<void> => {
    setIsLoading(true);

    const data: PresenceByArea[] = await getProductivityByArea(
      clientId,
      companyId,
      professionId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data) setPresenceAreaChartData(data);
    else setPresenceAreaChartData([]);

    setIsLoading(false);
  };

  const getRankChartDataApi = async (
    clientId: number,
    companies: string,
    professions: string,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setLowerProductivity: (lowerProductivity: number) => void,
    setHigherProductivity: (higherProductivity: number) => void,
    setAverage: (average: number) => void,
    setRankChartData: (value: RankByProfessionData[]) => void,
    setIsLoading: (value: boolean) => void,
    setIsLoadingAverage: (value: boolean) => void
  ): Promise<void> => {
    setIsLoading(true);
    setIsLoadingAverage(true);

    const data: RankByProfession = await getRankChartData(
      clientId,
      companies,
      professions,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.data && data.data.length > 0) {
      setRankChartData(data.data);
      setLowerProductivity(data.data[0].value);
      setHigherProductivity(data.data[data.data.length - 1].value);
      setAverage(data.average);
    } else {
      setRankChartData([]);
      setLowerProductivity(0);
      setHigherProductivity(0);
      setAverage(0);
    }

    setIsLoadingAverage(false);
    setIsLoading(false);
  };

  const getTimelineDataApi = async (
    clientId: number,
    areaSelected: string,
    companies: string,
    professions: string,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setTimelineData: (value: Timeline[]) => void,
    setTimelineValues: (arrayAvarageValues: Array<Timeline[]>) => void,
    dividerPerEighteen: (base: any[]) => Array<any[]>,
    setIsLoading: (value: boolean) => void,
    setIsEmpty: (value: boolean) => void,
    trackfy?: boolean
  ): Promise<void> => {
    setIsEmpty(false);
    setIsLoading(true);

    const data: Timeline[] = await getTimelineData(clientId, {
      companies,
      professions,
      areas: areaSelected,
      initialDate: moment(initialDate).startOf('day').valueOf(),
      finalDate: moment(finalDate).valueOf(),
      currentDate: moment().valueOf(),
      cancelToken,
      trackfy,
    });

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      batchedUpdates(() => {
        setTimelineValues(dividerPerEighteen(data));
        setTimelineData(dividerPerEighteen(data)[0]);
      });
      setIsEmpty(false);
    } else {
      batchedUpdates(() => {
        setTimelineValues([]);
        setTimelineData([]);
      });
      setIsEmpty(true);
    }

    setIsLoading(false);
  };

  const getAreaTimelineDataApi = async (
    clientId: number,
    areaSelected: string,
    companies: string,
    professions: string,
    initialDate: Date,
    finalDate: Date,
    cancelToken: CancelToken,
    setAreaTimelineData: (value: AreaTimeline[]) => void,
    setAreaTimelineValues: (arrayAvarageValues: Array<AreaTimeline[]>) => void,
    dividerPerEighteen: (base: any[]) => Array<any[]>,
    setIsLoading: (value: boolean) => void,
    setIsEmpty: (value: boolean) => void,
    trackfy?: boolean
  ): Promise<void> => {
    setIsEmpty(false);
    setIsLoading(true);

    const data: AreaTimeline[] = await getAreaTimelineData(clientId, {
      companies,
      professions,
      areas: areaSelected,
      initialDate: moment(initialDate).startOf('day').valueOf(),
      finalDate: moment(finalDate).valueOf(),
      currentDate: moment().valueOf(),
      cancelToken,
      trackfy,
    });

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      batchedUpdates(() => {
        setAreaTimelineValues(dividerPerEighteen(data));
        setAreaTimelineData(dividerPerEighteen(data)[0]);
      });
      setIsEmpty(false);
    } else {
      batchedUpdates(() => {
        setAreaTimelineValues([]);
        setAreaTimelineData([]);
      });
      setIsEmpty(true);
    }

    setIsLoading(false);
  };

  // Consumes reports endpoints========================================================================
  const checkReportExistence = async (
    id: string,
    filename: string,
    setLoadingDailyReport: (value: boolean) => void,
    setLoadingAreaMonitoringHistory: (value: boolean) => void
  ): Promise<void> => {
    const report: string = await getReport(id);

    if (report.length > 0) {
      downloadPdf(filename, report);
      if (filename === 'Relatorio Diario Sistema.pdf') setLoadingDailyReport(false);
      else {
        if (!isMounted.current) return;

        setLoadingAreaMonitoringHistory(false);
      }
    } else {
      setTimeout(async () => {
        checkReportExistence(id, filename, setLoadingDailyReport, setLoadingAreaMonitoringHistory);
      }, 30000);
    }
  };

  const generateAccessManagementApi = async (
    companyId: number,
    professionId: number,
    collaboratorId: number,
    initialDate: string,
    finalDate: string,
    setLoadingAccessControl: (value: boolean) => void
  ): Promise<void> => {
    setLoadingAccessControl(true);

    const report: string = await generateAccessManagement(
      companyId,
      professionId,
      collaboratorId,
      moment(initialDate).format('YYYY-MM-DD'),
      moment(finalDate).format('YYYY-MM-DD')
    );

    if (!isMounted.current) return;

    if (report.length > 0) {
      downloadPdf('Controle de Acesso.pdf', report);
      setLoadingAccessControl(false);
    }
  };

  const generateAreaMonitoringHistoryApi = async (
    companyId: number,
    professionId: number,
    collaboratorId: number,
    initialDate: string,
    finalDate: string,
    setLoadingDailyReport: (value: boolean) => void,
    setLoadingAreaMonitoringHistory: (value: boolean) => void
  ): Promise<void> => {
    setLoadingAreaMonitoringHistory(true);

    const reportId: string = await generateAreaMonitoringHistory(
      companyId,
      professionId,
      collaboratorId,
      moment(initialDate).format('YYYY-MM-DD'),
      moment(finalDate).format('YYYY-MM-DD')
    );

    if (reportId.length > 0) {
      setTimeout(
        () =>
          checkReportExistence(
            reportId,
            'Historico de Permanencia nas Areas.pdf',
            setLoadingDailyReport,
            setLoadingAreaMonitoringHistory
          ),
        30000
      );
    }
  };

  const generateDailyReportApi = async (
    companyId: number,
    professionId: number,
    setLoadingDailyReport: (value: boolean) => void,
    setLoadingAreaMonitoringHistory: (value: boolean) => void
  ): Promise<void> => {
    setLoadingDailyReport(true);

    const reportId: string = await generateDailyReport(
      companyId,
      professionId,
      true,
      true,
      true,
      true,
      true,
      true
    );

    if (reportId.length > 0) {
      setTimeout(
        () =>
          checkReportExistence(
            reportId,
            'Relatorio Diario Sistema.pdf',
            setLoadingDailyReport,
            setLoadingAreaMonitoringHistory
          ),
        30000
      );
    }
  };

  const generateSummaryApi = async (
    companyId: number,
    date: number,
    cancelToken: CancelToken,
    monthly: boolean,
    setData: (value: DailySummaryMonitor[]) => void,
    setLoading: (value: boolean) => void,
    setIsEmpty: (value: boolean) => void
  ): Promise<void> => {
    setLoading(true);
    const data: DailySummaryMonitor[] = await getSummary(companyId, date, cancelToken, monthly);

    if (data && data.length > 0) {
      setData(data);
      setIsEmpty(false);
    } else {
      setData([]);
      setIsEmpty(true);
    }

    setLoading(false);
  };

  const generateSummaryMediaApi = async (
    companyId: number,
    date: number,
    cancelToken: CancelToken,
    monthly: boolean,
    setData: (value: MediaData) => void,
    setLoading: (value: boolean) => void
  ): Promise<void> => {
    setLoading(true);
    const data: MediaData = await getSummaryMedia(companyId, date, cancelToken, monthly);

    if (data && data.media.length > 0) setData({ media: data.media });
    else setData({ media: '' });

    setLoading(false);
  };

  const generateMonitoredAreasApi = async (
    clientId: number,
    companyId: number,
    cancelToken: CancelToken,
    updateMonitoredAreas: (value: MonitoredAreasData[]) => void,
    setAreasLoading: (value: boolean) => void
  ): Promise<void> => {
    setAreasLoading(true);
    const data: MonitoredData = await getMonitoredAreasData(clientId, companyId, cancelToken);

    if (data && data.areas.length > 0) updateMonitoredAreas(data.areas);
    else updateMonitoredAreas([]);

    setAreasLoading(false);
  };

  const generateMonitoredCollaboratorsApi = async (
    clientId: number,
    companyId: number,
    cancelToken: CancelToken,
    updateMonitoredCollaborators: (value: MonitoringCollaboratorData[]) => void,
    setLoading: (value: boolean) => void
  ): Promise<void> => {
    setLoading(true);
    const data: MonitoringCollaboratorData[] = await getMonitoredCollaborators(clientId, companyId, cancelToken);

    if (data && data.length > 0) updateMonitoredCollaborators(data);
    else updateMonitoredCollaborators([]);

    setLoading(false);
  };

  const generateMachinesSummaryApi = async (
    cancelToken: CancelToken,
    setData: (value: MachinesSummaryData) => void,
    setLoading: (value: boolean) => void,
    setIsEmpty: (value: boolean) => void,
    setInicialValue: (value: string[]) => void
  ): Promise<void> => {
    setLoading(true);
    const data: MachinesSummaryData = await getMachinesSummary(cancelToken);
    if (data && data.data.length > 0) {
      setData(data);
      setIsEmpty(false);
      setInicialValue(data.statusValues);
    } else {
      setData({ data: [], statusValues: [], idValues: [] });
      setIsEmpty(true);
      setInicialValue([]);
    }

    setLoading(false);
  };

  const updateMachinesStatusApi = async (cancelToken: CancelToken, id: string, status: string): Promise<void> => {
    await putMachineStatus(status, id, cancelToken);
  };

  const getHeatMapDataApi = async (
    clientId: number,
    companyId: number,
    professionId: number,
    initialDate: number,
    finalDate: number,
    cancelToken: CancelToken,
    setIsLoading: (value: boolean) => void,
    setHeatMapData: (value: IHeatmapArea[]) => void,
    setIsEmpty: (value: boolean) => void
  ): Promise<void> => {
    setIsLoading(true);
    const data: IHeatmapArea[] = await getHeatMapData(
      clientId,
      companyId,
      professionId,
      moment(initialDate).startOf('day').valueOf(),
      moment(finalDate).valueOf(),
      cancelToken
    );

    if (!isMounted.current) return;

    if (data && data.length > 0) {
      setHeatMapData(data);
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }

    setIsLoading(false);
  };

  return {
    companies,
    professions,
    areas,
    companiesState,
    professionsState,
    areasState,
    getCollaboratorsApi,
    getCompaniesApi,
    getProfessionsApi,
    getAreasApi,
    getRankChartDataApi,
    getAverageTimeChartDataApi,
    getPeopleInAreasChartDataApi,
    getProductivityByAreaApi,
    getTimelineDataApi,
    getAreaTimelineDataApi,
    getCalendarDataApi,
    getCollaboratorTimelineDataApi,
    getBurndownChartDataApi,
    generateAccessManagementApi,
    generateAreaMonitoringHistoryApi,
    generateDailyReportApi,
    generateSummaryApi,
    generateSummaryMediaApi,
    generateMonitoredAreasApi,
    generateMonitoredCollaboratorsApi,
    generateMachinesSummaryApi,
    updateMachinesStatusApi,
    getHeatMapDataApi,
  };
};
