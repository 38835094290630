import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Axios, { AxiosResponse, CancelTokenSource } from 'axios';

import { SelectOption } from '../../components/Select/types';
import { getAreas } from '../../services/api/TrackfyService';
import { Area } from '../../types/Filters';
import { Select } from '../../components/Select';
import { useAuth } from '../../hooks/useAuth';
import { useAuthContext } from '../../stores/AuthContext';
import { mixpanelTrack } from '../../services/SystemMonitor';
import { getArea } from '../../services/api/TrackfyService/integration';
import { DisplacementBtwnAreasMarkerProps } from '../moviment/DiplacementBetweenAreas/components/DisplacementBtwnAreasMarker';
import { IconLocationVuesax } from '../../Icons/Location-Vuesax';
import { IconFinishVuesax } from '../../Icons/Finish-Vuesax';
import { selectedAreasMarkers } from '../moviment/DiplacementBetweenAreas/context/DisplacementBtwnAreasContext';

type AreaSelectProps = {
  label?: string;
  areaSelected: number;
  areaLabelSelected?: string;
  setAreaSelected: (value: number) => void;
  setAreaLabelSelected?: (value: string) => void;
  additionalFuncionOnchange?: () => void;
  setAreasMarkers?: Dispatch<SetStateAction<selectedAreasMarkers>>;
  initial?: boolean;
  screen?: string;
};

const AreaSelect: React.FC<AreaSelectProps> = (props: AreaSelectProps) => {
  const [areas, setAreas] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { USER_NAME } = useAuth();
  const { authData } = useAuthContext();
  const userName = localStorage.getItem(USER_NAME);
  const { monitorAgent } = useAuthContext();
  const { areaSelected, setAreaSelected, label, screen, additionalFuncionOnchange, setAreaLabelSelected, 
    setAreasMarkers, initial } = props;

  useEffect(() => {
    let mounted: boolean = true;
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    if (mounted) {
      setIsLoading(true);

      getAreas(currentRequest.token, true)
        .then((result: AxiosResponse<any>) => {
          setAreas(
            result.data.map((area: Area) => {
              return {
                label: area.name,
                value: area.id ? area.id.toString() : '0',
              };
            })
          );
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }

    return () => {
      mounted = false;
      currentRequest.cancel('getAreasApi request canceled by the user.');
    };
  }, []);

  const getAreaCoordinates = (areaID: number, initial:boolean) => {
    const currentRequest: CancelTokenSource = Axios.CancelToken.source();

    getArea(currentRequest.token, areaID).then((data: AxiosResponse<any>) => {
        const currentArea: DisplacementBtwnAreasMarkerProps = {
            contentName: data.data.name, 
            color: initial ? "#FFFFFF" : "#2F4CDD", 
            icon:initial ? <IconLocationVuesax stroke='#191919'/> : <IconFinishVuesax stroke='#FFFFFF'/>, 
            lat:Number.parseFloat(data.data.lat), 
            lng:Number.parseFloat(data.data.lgn), 
            coordinates: data.data.geofence
        };

        if(setAreasMarkers){
          if(initial)
              setAreasMarkers((prev: selectedAreasMarkers) => ({ ...prev, initialAreaOnMap: currentArea }));
          else 
              setAreasMarkers((prev: selectedAreasMarkers) => ({ ...prev, finalAreaOnMap: currentArea }));
        }
    })
    .catch((err: any) => console.log(err));
}

  const onChanging = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAreaSelected(Number(event.target.value));

    const label = event.target.selectedOptions[0].label;
    setAreaLabelSelected?.(label);
    
    if(initial != undefined)
      getAreaCoordinates(Number.parseInt(event.target.value), initial);

    if (label != 'Selecione uma área')
      mixpanelTrack(monitorAgent, `${screen} - Area ${label} selecionada`, userName, authData);
  };

  return (
    <Select
      id="avrgtime-area-select"
      label={label}
      placeholder="Selecione uma área"
      options={areas}
      value={areaSelected}
      loading={isLoading}
      onChanging={(event) => onChanging(event)}
    />
  );
};

export default AreaSelect;
