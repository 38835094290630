import React from 'react';

import { ArrowRightVuesax } from '../../../../Icons/ArrowRight-Vuesax';
import { MapTracking } from '../../../../Icons/MapTracking';
import { MonitoredAreaCollaborators } from './MonitoredAreaCollaborators';
import { useMonitoringContext } from './context/MonitoringContext';
import { MonitoredAreasInEmergencyData } from '../../../../types/MonitoredData';
import { useAreaCollaborators } from '../../../../components/MonitoredMap/hooks/useAreaCollaborators';
import { IconLoading } from '../../../../components/IconLoading';
import { useAreasContext } from '../MonitoringCard/context/AreasContext';

import * as S from './styles';

interface Props {}
export const MonitoredAreas: React.FC<Props> = ({}) => {
  const { activeArea, setActiveArea } = useMonitoringContext();
  const { setCurrentCollaborators } = useAreasContext();
  const { areaCollaborators } = useAreaCollaborators(false, "notevacuating", setCurrentCollaborators, [], 1000 * 120);
  
  const toggleActiveArea = (area: MonitoredAreasInEmergencyData) => setActiveArea(area);

  if (!areaCollaborators.length) {
    return (
      <S.MonitoredAreasSection>
        <IconLoading />
      </S.MonitoredAreasSection>
    );
  }

  return (
    <S.MonitoredAreasSection>
      <S.MonitoredAreasNames>
        <S.MonitoredAreasHeader>
          <MapTracking />
          <p>Áreas monitoradas</p>
        </S.MonitoredAreasHeader>
        {areaCollaborators
          .filter((v) => v.nome !== 'ÁREA NÃO MAPEADA')
          .map((item, index) => {
            const isActive = activeArea?.nome === item.nome;
            return (
              <S.MonitoredAreasItem
                active={isActive}
                key={`${item.nome}-${index}`}
                onClick={() => toggleActiveArea(item)}
              >
                <span>{item.total}</span>
                <p>{item.nome}</p>
                {isActive ? <></> : <ArrowRightVuesax />}
              </S.MonitoredAreasItem>
            );
        })}
      </S.MonitoredAreasNames>
      <MonitoredAreaCollaborators />
    </S.MonitoredAreasSection>
  );
};
